<template>
	<el-dialog title="添加客户代理绑定关系" top="1vh" width="600px" :visible.sync="is_show_in_page">
		<el-form label-width='90px'>
			<el-form-item label="选择客户">
				<el-select v-model="form.customer_user_num" style="width:100%" clearable>
					<el-option 
						v-for="(item,index) of customer_user_list" 
						:key="index" :value="item.customer_user_num" 
						:label="`${item.tel}/${item.user_name}/${item.company_name}`" 
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="代理名称">
				<el-input v-model="customer_agent.name" clearable>
					<el-button @click="customer_agent_ser" type="success" slot="append">查找</el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="代理信息">
				<el-input v-model="customer_agent.info_text" :disabled="true" clearable>
					<el-button @click="customer_agent_clear" type="success" slot="append">清空</el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="匹配词">
				<el-input v-model="form.tord_match_addr" clearable></el-input>
			</el-form-item>
			<el-form-item label="匹配词简称">
				<el-input v-model="form.tord_match_addr_show" clearable></el-input>
			</el-form-item>
			<el-form-item label="备注">
				<el-input v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item >
				<el-button type="primary" @click="sub">提交</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>
<script>
	export default {
		props:{
			is_show:Number,
			customer_user_list:Array,
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,

				//代理信息
				customer_agent:{
					name:'',
					info_text:'',
					customer_agent_num:''
				},

				//表单
				form:{
					customer_user_num:'',//客户编号
					tord_match_addr:'',//匹配词
					tord_match_addr_show:'',//匹配词简称
					mark:'',//备注
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;

					//初始化
					this.init();
					
				}else this.is_show_in_page=false;
			}
		},
		methods: {

			//提交
			sub(){
				
				if(!this.form.customer_user_num.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少客户信息'
					});
					return;
				}

				if(!this.customer_agent.customer_agent_num.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少代理信息'
					});
					return;
				}

				if(!this.form.tord_match_addr.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少匹配词'
					});
					return;
				}

				//提交数据
				this.$my.net.req({
					data:{
						mod:'customer',
						ctr:'agent_bind_add_by_admin',
						customer_agent_num:this.customer_agent.customer_agent_num,
						...this.form,
					},
					callback:(data)=>{

						//添加成功
						this.$my.other.confirm({
							content:"添加成功,是否前往关系列表?",
							confirm:()=>{

								//通知
								this.$emit("added");
							}
						});
					}
				});
			},

			//初始化
			init(){

				//清空表单数据
				this.form.tord_match_addr=''
				this.form.tord_match_addr_show=''
				this.form.mark=''

				//清空代理数据
				this.customer_agent_clear();
			},

			//清空本页数据
			clear(){

				this.$my.other.confirm({
					content:"点击确定清空本页数据",
					confirm:()=>{

						//初始化
						this.init();
					}
				});
			},

			//代理查找相关
			customer_agent_ser(){//查找客户

				//格式判断
				if(!this.customer_agent.name.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'代理名称为空'
					});
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'customer',
						ctr:'agent_list_by_admin',
						name:this.customer_agent.name
					},
					callback:(data)=>{
						
						data=data.list[0];

						if(!data){
							this.$my.other.msg({
								type:"warning",
								str:'不存在的代理'
							});
							return;
						}

						this.customer_agent.customer_agent_num=data.customer_agent_num
						this.customer_agent.info_text=`${data.name}/${data.city}/${data.county}/${data.addr}`
					}
				});
			},
			customer_agent_clear(){//清空客户信息
				this.customer_agent.name=''
				this.customer_agent.info_text=''
				this.customer_agent.customer_agent_num=''
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.bottom_btns{
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: calc(100% - 60px);
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form{
		height: calc(100% - 100px);
		overflow-y:auto;
		padding-bottom:75px;
		margin-top:-20px;
	}

	.page{
		position:relative;
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

</style>