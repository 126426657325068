import { render, staticRenderFns } from "./choose.vue?vue&type=template&id=be29bfd6&scoped=true&"
import script from "./choose.vue?vue&type=script&lang=js&"
export * from "./choose.vue?vue&type=script&lang=js&"
import style0 from "./choose.vue?vue&type=style&index=0&id=be29bfd6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.10.0_geeguvfn2njjaew5w23blzhalq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be29bfd6",
  null
  
)

export default component.exports