 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="100px">
			<el-form-item class="el_form_item" label="客户名称">
				<el-select class="el_input" v-model="form.customer_user_num" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option v-for="(item,index) of customer_user_list" :key="index" :label="`${item.user_name}/${item.company_name}`" :value="item.customer_user_num"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="代理名称">
				<el-input class="el_input" v-model="form.customer_agent_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="代理联系人">
				<el-input class="el_input" v-model="form.customer_agent_link_man" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="代理联系电话">
				<el-input class="el_input" v-model="form.customer_agent_link_tel" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="匹配词">
				<el-input class="el_input" v-model="form.tord_match_addr" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="正常" value="1"></el-option>
					<el-option label="停用" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="线路绑定">
				<el-select class="el_input" v-model="form.logistics_route_bind_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="已绑定" value="1"></el-option>
					<el-option label="未绑定" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="报价绑定">
				<el-select class="el_input" v-model="form.price_add_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="已绑定" value="1"></el-option>
					<el-option label="未绑定" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注">
				<el-input class="el_input" v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="success" @click="add_view_open">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="添加/修改时间" width="175">
					<template slot-scope="scope">
						<div class="tab_line_item">添加: {{scope.row.create_time_text}}</div>
						<div class="tab_line_item">修改: {{scope.row.update_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="客户信息" width="240">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.customer_user_info.tel}}/{{scope.row.customer_user_info.user_name}}</div>
						<div class="tab_line_item">{{scope.row.customer_user_info.company_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="代理信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.customer_agent_info.name}}</div>
						<div class="tab_line_item">{{scope.row.customer_agent_info.city}}/{{scope.row.customer_agent_info.county}}/{{scope.row.customer_agent_info.addr}}</div>
						<div class="tab_line_item">
							<span v-if="scope.row.customer_agent_info.link_man">{{scope.row.customer_agent_info.link_man}}</span>
							<span v-if="scope.row.customer_agent_info.link_man && scope.row.customer_agent_info.link_tel">/</span>
							<span v-if="scope.row.customer_agent_info.link_tel">{{scope.row.customer_agent_info.link_tel}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="线路/报价状态" width="110">
					<template slot-scope="scope">
						<div class="tab_line_item">线路: {{scope.row.is_set_logistics_route}}</div>
						<div class="tab_line_item">报价: {{scope.row.is_set_logistics_route_price}}</div>
					</template>
				</el-table-column>
				<el-table-column label="备注/匹配词" width="180">
					<template slot-scope="scope">
						<div class="tab_line_item">备注: {{scope.row.mark?scope.row.mark:'无'}}</div>
						<div class="tab_line_item">匹配: {{scope.row.tord_match_addr}}</div>
						<div class="tab_line_item">简称: {{scope.row.tord_match_addr_show}}</div>
					</template>
				</el-table-column>
				<el-table-column label="状态" prop="status_text" width="80"></el-table-column>
				
				
				<!-- 操作行 -->
				<el-table-column label="操作" width="120">
					<template slot-scope="scope">
						<el-button @click="edit_view_open(scope.row)" size="mini" type="text">修改</el-button>
						<el-button v-if="scope.row.status==1" @click="status_change(scope.row,2)" size="mini" type="text">停用</el-button>
						<el-button v-if="scope.row.status==2" @click="status_change(scope.row,1)" size="mini" type="text">启用</el-button>
						<el-button @click="del(scope.row,3)" size="mini" type="text">删除</el-button>
						<auth-button @click="route_and_price_set_view_open(scope.row)" size="mini" type="text" style="margin:0">线路与报价</auth-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<!-- 修改弹出层 -->
		<edit 
			:is_show="edit.is_show" 
			:data="edit.data" 
			@edited="edit_view_close" 
		></edit>

		<!-- 添加弹出层 -->
		<add 
			:is_show="add.is_show" 
			:customer_user_list="customer_user_list" 
			@added="add_view_close"
		></add>

		<!-- 线路与报价弹出层 -->
		<routeAndPrice 
			:is_show="route_and_price.is_show" 
			:bind_info="route_and_price.bind_info" 
			@closed="route_and_price_set_view_close"
		></routeAndPrice>

	</div>
</template>

<script>
	import add from './add.vue'
	import edit from './edit.vue'
	import routeAndPrice from './route_and_price.vue'
	export default {
		components:{
			add,
			edit,
			routeAndPrice
		},
		data() {
			return {

				//搜索条件
				form: {
					customer_user_num:'',
					customer_agent_name:'',
					customer_agent_link_man:'',
					customer_agent_link_tel:'',
					tord_match_addr:'',
					logistics_route_bind_status:'',
					price_add_status:'',
					status:'',
					mark:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//基础数据
				customer_user_list:[],

				//添加弹窗
				add:{
					is_show:0,
				},

				//修改弹窗
				edit:{
					data:{},
					is_show:0,
				},

				//线路与报价设定页面
				route_and_price:{
					bind_info:{},
					is_show:0,
				},
			}
		},
		created() {

			//读取客户列表
			this.get_customer_user_list()

			//读取用户组数据
			this.get_page_data()
		},
		methods: {

			//打开线路与报价设定页面
			route_and_price_set_view_open(item){
				this.route_and_price.bind_info=item
				this.route_and_price.is_show++
			},
			route_and_price_set_view_close(item){
				this.route_and_price.is_show=0
				this.get_page_data()
			},

			//审核状态变更
			status_change(item,new_status){

				//提示内容
				switch(new_status){
					case 1:var confirm_content="点击确定启用此项";break;
					case 2:var confirm_content="点击确定停用此项";break;
				}

				//询问
				this.$my.other.confirm({
					content:confirm_content,
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'customer',
								ctr:'agent_bind_edit_by_admin',
								id:item.id,
								status:new_status
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.get_page_data()
							},
						});
					}
				});
			},

			//删除店铺
			del(item){

				//询问
				this.$my.other.confirm({
					content:"是否删除此绑定关系",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'customer',
								ctr:'agent_bind_del_by_admin',
								id:item.id
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.get_page_data()
							},
						});
					}
				});
			},

			//添加页面
			add_view_open(){
				this.add.is_show++
			},
			add_view_close(){
				this.add.is_show=0
				this.get_page_data()
			},

			//打开用户组修改页面
			edit_view_open(item){
				this.edit.data=item
				this.edit.is_show++
			},
			edit_view_close(item){
				this.edit.is_show=0
				this.get_page_data()
			},
			
			//搜索
			page_ser(){
	
				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取用户组数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'customer',
						ctr:'agent_bind_list_by_admin',
						is_get_customer_user_info:1,
						is_get_customer_agent_info:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//创建时间
							item.create_time_text=this.$my.other.totime(item.create_time);

							//上次修改时间
							item.update_time_text=this.$my.other.totime(item.update_time);

							//状态(1:正常,2:停用)
							switch(item.status){
								case '1':item.status_text="正常";break;
								case '2':item.status_text="停用";break;
							}

							//是否已设定新路信息
							if(item.logistics_route_num){
								item.is_set_logistics_route="已设定";
							}else item.is_set_logistics_route="未设定";

							//是否已设定新路信息
							if(item.weight_price>0 || item.volume_price>0 || item.number_price>0){
								item.is_set_logistics_route_price="已报价";
							}else item.is_set_logistics_route_price="未报价";
						}

						//渲染
						this.list.data=data.list
					}
				})
			},

			//读取客户列表
			get_customer_user_list(){

				//读取数据
				this.$my.net.req({
					data:{
						mod:'customer',
						ctr:'user_list_by_admin',
						status:1,
						num:10000
					},
					callback:(data)=>{

						this.customer_user_list=data.list
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped>

	//修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		width: 90%;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 140px);
	}
	.el_form_item{
		width: 16.667%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>