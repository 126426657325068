 <template>
	<el-dialog 
		title="线路与报价" 
		top="1vh" width="1080px" 
		append-to-body 
		custom-class="el-dialog-s" 
		:visible.sync="is_show_in_page" 
		@close="page_close"
	>

		<!-- 表单 -->
		<el-form class="form" label-width="100px">
			<div class="big_tit" style="margin:0">
				<div>线路详情</div>
				<el-button @click="route_choose_open" type="primary" size="mini" style="position:absolute;left:120px;top:18px">选择线路</el-button>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="物流公司" style="width:100%">
					<el-input class="el_inner_width" v-model="route_info_in_page.logistics_company_info_text" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货网点" style="width:100%">
					<el-input class="el_inner_width" v-model="route_info_in_page.case_node_info_text" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="中转网点" style="width:100%">
					<div style="display:flex;width:100%;">
						<div class="el_inner_width inner_div">
							<el-tag 
								style="display: inline-block;margin:4px"
								v-for="(node_info_text,index) in route_info_in_page.transit_nodes" 
								:key="index" 
							>{{node_info_text}}</el-tag>
						</div>
					</div>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="卸货网点" style="width:100%">
					<el-input class="el_inner_width" v-model="route_info_in_page.aim_node_info_text" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="重量单价" style="width:320px">
					<el-input class="el_inner_width" v-model="route_info_in_page.weight_price" type="number" disabled>
						<template slot="append">元/吨</template>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="体积单价" style="width:320px">
					<el-input class="el_inner_width" v-model="route_info_in_page.volume_price" type="number" disabled>
						<template slot="append">元/方</template>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="件数单价" style="width:329px">
					<el-input class="el_inner_width" v-model="route_info_in_page.number_price" type="number" disabled>
						<template slot="append">元/件</template>
					</el-input>
				</el-form-item>
			</div>


			<div class="big_tit">我给客户的报价</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="重量单价" style="width:320px">
					<el-input class="el_inner_width" v-model="form.weight_price" type="number" clearable>
						<template slot="append">元/吨</template>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="体积单价" style="width:320px">
					<el-input class="el_inner_width" v-model="form.volume_price" type="number" clearable>
						<template slot="append">元/方</template>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="件数单价" style="width:329px">
					<el-input class="el_inner_width" v-model="form.number_price" type="number" clearable>
						<template slot="append">元/件</template>
					</el-input>
				</el-form-item>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub">提交</el-button>
			<el-button type="danger" @click="clear">还原</el-button>
		</div>

		<!-- 选择线路 -->
		<routeChoose 
			:is_show="route_choose.is_show" 
			@choosed="route_choosed"
		></routeChoose>

	</el-dialog>
</template>

<script>
	import routeChoose from '@/pages/logistics/route/choose.vue'
	export default {
		props:{
			is_show:Number,
			bind_info:Object
		},
		components:{
			routeChoose
		},
		data() {
			return {

				is_show_in_page:false,

				//页面内的线路详情
				route_info_in_page:{
					logistics_company_info_text:'',
					case_node_info_text:'',
					transit_nodes:[],
					aim_node_info_text:'',
					weight_price:'',
					volume_price:'',
					number_price:'',
				},

				//报价表单
				form:{
					logistics_company_num:'',
					logistics_route_num:'',
					weight_price:'',
					volume_price:'',
					number_price:'',
				},

				//线路选择
				route_choose:{
					is_show:0,
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开页面
					this.is_show_in_page=true;

					//读取用户组数据
					this.init()
					
				}else this.is_show_in_page=false;
			}
		},
		methods: {

			//初始化
			sub(){

				if(!this.form.logistics_company_num.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少物流公司编号'
					});
					return;
				}

				if(!this.form.logistics_route_num.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少线路编号'
					});
					return;
				}

				if(!this.$my.check.is_num(this.form.weight_price)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少重量单价'
					});
					return;
				}

				if(!this.$my.check.is_num(this.form.volume_price)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少体积单价'
					});
					return;
				}

				if(!this.$my.check.is_num(this.form.number_price)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少件数单价'
					});
					return;
				}

				//提交数据
				this.$my.other.confirm({
					content:"点击确定修改客户代理绑定关系的线路与报价数据",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'customer',
								ctr:'agent_bind_edit_by_admin',
								id:this.bind_info.id,
								...this.form,
							},
							callback:(data)=>{

								//通知
								this.$emit("closed");
							}
						});
					}
				});
			},
			
			//清空本页数据
			clear(){

				this.$my.other.confirm({
					content:"点击确定还原本页数据",
					confirm:()=>{

						//初始化
						this.init();
					}
				});
			},

			//初始化
			init(){

				//页面内的线路详情初始化
				this.route_info_in_page.logistics_company_info_text=''
				this.route_info_in_page.case_node_info_text=''
				this.route_info_in_page.transit_nodes=[]
				this.route_info_in_page.aim_node_info_text=''
				this.route_info_in_page.weight_price=''
				this.route_info_in_page.volume_price=''
				this.route_info_in_page.number_price=''
				
				//报价表单初始化
				this.form.logistics_company_num=this.bind_info.logistics_company_num
				this.form.logistics_route_num=this.bind_info.logistics_route_num
				this.form.weight_price=this.bind_info.weight_price
				this.form.volume_price=this.bind_info.volume_price
				this.form.number_price=this.bind_info.number_price

				//读取线路详情
				this.get_route_data(this.form.logistics_route_num)
			},

			//线路选择界面
			route_choose_open(){

				//打开弹出层
				this.route_choose.is_show++
			},
			route_choosed(route_info){

				//关闭弹出层
				this.route_choose.is_show=0

				//写入线路编号和物流公司编号
				this.form.logistics_company_num=route_info.logistics_company_num
				this.form.logistics_route_num=route_info.logistics_route_num

				//线路详情渲染
				this.route_info_in_page_render(route_info);
			},

			//页面关闭时
			page_close(){

				this.$emit("closed");
			},

			//线路详情渲染
			route_info_in_page_render(route_info){

				let route_info_in_page={
					logistics_company_info_text:
						route_info.logistics_company_info.name+'/'+
						route_info.logistics_company_info.link_man+'/'+
						route_info.logistics_company_info.link_tel,

					case_node_info_text:
						route_info.case_node_info.logistics_park_info.name+'/'+
						route_info.case_node_info.name+'/'+
						route_info.case_node_info.logistics_park_info.city+
						route_info.case_node_info.logistics_park_info.county+'/'+
						route_info.case_node_info.link_man+'/'+
						route_info.case_node_info.link_tel,

					transit_nodes:[],

					aim_node_info_text:
						route_info.aim_node_info.logistics_park_info.name+'/'+
						route_info.aim_node_info.name+'/'+
						route_info.aim_node_info.logistics_park_info.city+'/'+
						route_info.aim_node_info.logistics_park_info.county+'/'+
						route_info.aim_node_info.link_man+'/'+
						route_info.aim_node_info.link_tel,

					weight_price:route_info.weight_price,
					volume_price:route_info.volume_price,
					number_price:route_info.number_price,
				}

				for(var indexs in route_info.transit_nodes){

					var transit_node_item=route_info.transit_nodes[indexs]

					route_info_in_page.transit_nodes.push(
						transit_node_item.logistics_park_info.city+'/'+
						transit_node_item.logistics_park_info.county+'/'+
						transit_node_item.logistics_park_info.name+'/'+
						transit_node_item.name
					);
				}

				//置入页面
				this.route_info_in_page=route_info_in_page;
			},

			//读取用户组数据
			get_route_data(logistics_route_num){

				if(!logistics_route_num){
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'logistics',
						ctr:'route_list_by_admin',
						is_get_logistics_company_info:1,
						is_get_case_node_info:1,
						is_get_case_node_park_info:1,
						is_get_aim_node_info:1,
						is_get_aim_node_park_info:1,
						is_get_transit_nodes:1,
						is_get_transit_nodes_park_info:1,
						logistics_route_num:logistics_route_num
					},
					callback:(data)=>{

						//读取失败处理
						if(data.list.length!=1){
							this.$my.other.msg({
								type:'warning',
								str:'读取原线路数据失败,请重新选择路线'
							});
							return;
						}

						//线路详情渲染
						this.route_info_in_page_render(data.list[0]);
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.bottom_btns{
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: calc(100% - 60px);
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form{
		height: calc(100% - 100px);
		overflow-y:auto;
		padding-bottom:75px;
		margin-top:-20px;
	}

	.inner_div{
		width:85.977%;
		height: 78px;
		line-height:39px;
		border: 1px solid #E4E7ED;
		background-color: #F5F7FA;
		border-radius: 4px;
		display:flex;
		flex-wrap:wrap;
	}

	.el-dialog-s{
		z-index: 11;
	}

</style>