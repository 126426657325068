<template>
	<el-dialog title="修改客户代理绑定关系" top="1vh" width="400px" :visible.sync="is_show_in_page">
		
		<!-- 表单 -->
		<el-form label-width="90px">
			<el-form-item label="匹配词">
				<el-input v-model="form.tord_match_addr" clearable></el-input>
			</el-form-item>
			<el-form-item label="匹配词简称">
				<el-input v-model="form.tord_match_addr_show" clearable></el-input>
			</el-form-item>
			<el-form-item label="备注">
				<el-input v-model="form.mark" clearable></el-input>
			</el-form-item>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub">提交</el-button>
			<el-button type="danger" @click="clear">还原</el-button>
		</div>
	</el-dialog>
</template>
<script>
	export default {
		props:{
			is_show:Number,
			data:Object
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,

				//表单
				form:{
					tord_match_addr:'',//匹配词
					tord_match_addr_show:'',//匹配词简称
					mark:'',//备注
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;

					//初始化
					this.init();
					
				}else this.is_show_in_page=false;
			}
		},
		methods: {

			//提交
			sub(){

				if(!this.form.tord_match_addr.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少匹配词'
					});
					return;
				}

				//提交数据
				this.$my.other.confirm({
					content:"点击确定修改客户代理绑定关系",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'customer',
								ctr:'agent_bind_edit_by_admin',
								id:this.data.id,
								...this.form,
							},
							callback:(data)=>{

								//通知
								this.$emit("edited");
							}
						});
					}
				});
			},

			//初始化
			init(){

				//清空表单数据
				this.form.tord_match_addr=this.data.tord_match_addr
				this.form.tord_match_addr_show=this.data.       
				this.form.mark=this.data.mark
			},

			//清空本页数据
			clear(){

				this.$my.other.confirm({
					content:"点击确定清空本页数据",
					confirm:()=>{

						//初始化
						this.init();
					}
				});
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.bottom_btns{
		text-align: center;
	}

	.page{
		position:relative;
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

</style>